module.exports = [{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en","configPath":"/home/runner/work/PhotoGallery/PhotoGallery/i18n/config.json"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"locales":"./i18n/languages","i18nextOptions":{"ns":["lang"],"defaultNS":"lang"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"React Academy","short_name":"React Academy","start_url":"/","background_color":"#344880","theme_color":"#ffc000","display":"minimal-ui","icon":"src/images/logoReact1000px.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"be4ce15c6fef5adae77ec2ee69fd3beb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-150592182-1","head":true,"anonymize":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"6722565","respectDNT":false,"productionOnly":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
